<template>
  <div class="infoPage">
    <div class="topImg">
      <!--      <img :src="dataInfo.backgroundImage" style="object-fit: cover;" alt="">-->
    </div>

    <div class="conBox">
      <div class="navListBox">{{ titleStr }} </div>
      <div class="infoBox">
        <div class="title">
          <div class="tit">{{ dataInfo.aboutasTitle }}</div>
          <div class="time">{{ dataInfo.aboutasCreateTime }}</div>
        </div>
        <div class="titleDate">
          <div class="tags"><span v-for="(item,index) in dataInfo.tags">{{item}}</span></div>
          <div class="num el-icon-view">&nbsp;&nbsp;{{ dataInfo.totalVisitNum || 0 }}</div>
        </div>
        <div class="X"></div>
        <div class="text" v-html="dataInfo.aboutasContent">

        </div>

      </div>
      <!--      <div className="Dynamic">
              <div className="title">
                <div className="text" v-if="tType == 'CH'">
                  其他推荐
                </div>
                <div className="text" v-if="tType == 'EN'">
                  Other recommended
                </div>
                <div className="lookAll">
                  &lt;!&ndash;            查看更多&ndash;&gt;
                  <span className="el-icon-arrow-left"></span>
                  <span className="el-icon-arrow-right" style="margin-right: 0;"></span>
                </div>
              </div>
              <div className="DyList">
                <div className="item" v-for="(item,index) in 3">
                  <div className="img scale"><img src="../../assets/img/swiperPage/PWYY5.png" alt=""></div>
                  <div className="con">
                    <div className="tit">
                      清名桥历史文化街区上榜国家级…
                    </div>
                    <div className="time">
                      2022-01-24
                    </div>
                    <div className="conText">
                      文化和旅游部办公厅公示首批国家级旅游休闲街区名单，无锡市梁溪区清名桥历史文化街区等三地入选
                    </div>
                  </div>

                </div>
              </div>
            </div>-->
    </div>

    <!--    <div class="paging-device">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              :page-size="per_page"
              :total="totalCount">
          </el-pagination>
        </div>-->
  </div>
</template>

<script>
import {getAboutasInfo} from '@/api/index'

export default {
  name: "indexInfo",
  components: {},
  data() {
    return {
      tType: 'CH',
      url: '',
      dataInfo: {},
      titleStr: '',
    }
  },
  created() {
    this.titleStr = '当前位置：'+ this.$route.matched[0].meta.title +'>' + this.$route.meta.title
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.createlist(this.$route.query.id)
  },
  methods: {
    // 每页条数
    handleSizeChange(val) {
      this.per_page = val
      this.createlist()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page = val
      this.createlist()
    },
    createlist(aboutasId) {
      getAboutasInfo(aboutasId, {}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.dataInfo = res.data
          if(res.data.backgroundImage){this.dataInfo.backgroundImage = res.data.backgroundImage.split(',')}

          if(this.dataInfo.tags && this.dataInfo.tags.trim().length>0){this.dataInfo.tags = res.data.tags.split(',')}
          // this.totalCount=res.total
        }
      })
    },
    select() {

    },
    changep() {

    }
  }
}
</script>

<style scoped lang="less">
.infoPage {
  width: 100%;
  //height: 800px;
  min-height: 800px;
  //padding: 0 0 0 55px;
  .topImg {
    width: 100%;
    //height: 800px;
    height: 280px;
    //background: #007B8A ;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .conBox {
    width: 1300px;
    //margin: -100px auto 0;
    margin: -270px auto 0;
    padding-bottom: 180px;
    position: relative;
    .navListBox{
      height: 70px;
      //background: #F9F9F9;
      border-radius: 40px 40px 0px 0px;
      font-size: 14px;
      font-family: STSongti-SC-Bold, STSongti-SC;
      font-weight: bold;
      color: #333333;
      line-height: 70px;
      padding-left: 50px;
      span{
        color: #007B8A;
      }
    }
    .infoBox {
      width: 100%;
      //background: #FFFFFF;
      //border-radius: 40px 40px 0px 0px;
      padding: 50px;

      .title {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tit {
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          max-width: 80%;
        }

        .time {
          margin: 20px 0;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
        }
      }

      .titleDate {
        margin: 20px 0 30px;
        display: flex;
        align-items: center;

        .tags {
          span {
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            padding: 3px 13px 4px 12px;
            margin-left: 10px;
            background: rgba(120, 120, 120, 0.1);
            border-radius: 17px;

            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }

        .num {
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
          margin-left: 30px;
        }
      }

      .X {
        width: 100%;
        height: 1px;
        background: #DDDDDD;
        margin: 0 0 50px 0;
      }

      .text {
        //font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        //font-weight: bold;
        color: #333333;
        line-height: 30px;
      }

      .textBox {
        font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 32px;
      }

      .imgBox {
        display: flex;
        margin-bottom: 200px;

        .left, .right {
          width: 50%;
          //width: 600px;
          //height: 800px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        //.right{
        //  width: 50%;
        //  background: pink;
        //display: flex;
        //flex-wrap: wrap;
        //.item{
        //  flex: none;
        //  width: 50%;
        //  height: 400px;
        //}
        //}
      }
    }

    .Dynamic {
      //background: #F5F5F5;
      padding: 60px 0 0px 0;

      .title {
        width: 1200px;
        margin: 0px auto 30px;
        display: flex;
        justify-content: space-between;
        align-items: end;

        .text {
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;

          span {
            color: #337D8D;
          }
        }

        .lookAll {
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #000000;
          cursor: pointer;

          span {
            margin: 0 20px;
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
              color: #337D8D;
            }
          }
        }
      }

      .DyList {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;

        .item {
          flex: none;
          width: 380px;
          min-height: 450px;
          height: 100%;
          background: #FFFFFF;
          border-radius: 20px;
          overflow: hidden;

          &:hover {
            .img {
              transition: all 1000ms ease;
              //transform: scale(1);
              transform: scale(1.1);
            }

            .con > .tit {
              color: #337D8D;
            }
          }

          .img {
            width: 100%;
            height: 300px;
            transition: all 500ms ease;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .con {
            padding: 24px;

            .tit {
              font-size: 20px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 900;
              color: #333333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1; //行数
              overflow: hidden;
            }

            .time {
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 500;
              color: #999999;
              margin: 16px 0;
            }

            .conText {
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #999999;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //行数
              overflow: hidden;
            }
          }

        }
      }
    }

  }


}
</style>

